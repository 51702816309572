import { FunctionComponent } from "react";
import { SectionText } from "./StyleComponents";

export const About: FunctionComponent = () => {
  return (
    <div className="centerText">
    <div
      className="pillBoxResponsive myBackgroundAndBorderColorAndTextColor limitWidth"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: ".5em",
        marginTop: ".5em",
        backgroundColor:"white",
width:"540px", 

      }}
    >
    <img
        style={{ maxWidth: "100%", padding:"0px" }}
            src="art/tarot_kickstarter_image_1.jpg"
            alt="T5NY is a 78-card Tarot Masterpiece and sci-fi novel by academic, author, entrepreneur, and skeptical atheist Dr. Clement Shimizu, Ph.D. of The Elumenati"
         />
<br/>
<img
        style={{ maxWidth: "100%", padding:"0px" }}
        src="art/tarot_kickstarter_image_2.jpg"
        alt="His prodigy apprentice turned long time art collaborator Yulika Murakami."
     />
    <br/>
     {  /*
<h1>Tarot Cards Availible Soon</h1>
<SectionText>
T5NY is a 78-card tarot masterpiece and sci-fi novel by academic, author, entrepreneur, and skeptical atheist Dr. Clement Shimizu, Ph.D. of The Elumenati and his prodigy apprentice turned long time art collaborator Yulika Murakami.
</SectionText>
*/
}
<br/>

        <video    style={{maxWidth:"100%",
        
      borderRadius:"1em"
      }}   loop autoPlay muted playsInline controls={false}  src="/video/CarolAndEddieAtWashingtonSquarePark.mp4" 
        
/>

<div style={{maxWidth:"500px", marginLeft:"auto",marginRight:"auto", textAlign:"left"}}>
<h1>
    <a href="https://www.kickstarter.com/projects/drclementshimizu/t5ny-tarot">
      <i><b>See the finished product at kickstarter.com</b></i>
  </a>
  </h1>


</div>

</div>
    </div>
  );
   /*

   <h2>More info</h2>
<ul>
  <li>78 Card Tarot Deck</li>
  <li>
  The Tarot Deck Includes three lessons for all skill levels:
  <ul>
    <li>Learn Tarot With Subconscious HYPER-CREATIVITY</li>
    <li>Skeptical Cartomancy</li>
    <li>The Delusional Action Manifesto!</li>
  </ul>
  </li>
  <li>A product of Human Spirit! All illustrations are done by humans!</li>

</ul>
<img
        style={{ maxWidth: "100%", padding:"0px" }}
        src="art/t5ny_Tarot_Cards_small.jpg"
        className={"aboutImage tight"}
        alt="The tarot cards"
     />
     */
};

export const Novel: FunctionComponent = () => {
  return (
     <div className="centerText">
    <div
      className="pillBoxResponsive myBackgroundAndBorderColorAndTextColor limitWidth"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: ".5em",
        marginTop: ".5em",
        backgroundColor:"white",
        width:"540px", 
        textAlign:"left"
      }}
    >

<h1 style={{textAlign:"center", marginTop:0 }}>Novel Teaser...</h1>
      <SectionText>
        The Tarot Cards illustrate an upcoming science fiction about party animals in space - their rise,
        fall, and redemption.
      </SectionText>
      <img
        style={{ maxWidth: "100%", padding:"0px", marginTop:".5em", marginBottom:".5em" }}
        className={"aboutImage tight"}
            src="art/Dr_Clement_Shimizu_Tarot_Deck.jpg"
            alt="T5NY is a 78-card Tarot Masterpiece and sci-fi novel by academic, author, entrepreneur, and skeptical atheist Dr. Clement Shimizu, Ph.D. of The Elumenati"
         />
      <SectionText>
        In the 22nd century, America's money and power is concentrated in New
        York City. Life is a glamorous game of fashion, flair, and ball culture.
      </SectionText>
      <br/>
      <SectionText>
        The 5 boroughs of NYC each had communities and culture that unique to
        the region. Each borough launched a spacecraft to extend the reach of
        their influence to the heavens. The space communities came to be known
        as the Five Houses of Nova York.
      </SectionText>
      <h3>Coming Soon...</h3>
      <br/>
    </div>
    </div>
    
  );
};

/* <SectionText>
        The Tarot Cards illustrate an upcoming science fiction about party animals in space - their rise,
        fall, and redemption.
      </SectionText>
      <SectionText>
        In the 22nd century, America's money and power is concentrated in New
        York City. Life is a glamorous game of fashion, flair, and ball culture.
      </SectionText>
      <SectionText>
        The 5 boroughs of NYC each had communities and culture that unique to
        the region. Each borough launched a spacecraft to extend the reach of
        their influence to the heavens. The space communities came to be known
        as the Five Houses of Nova York.
      </SectionText>
     */